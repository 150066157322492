.loading-nfts {
    text-shadow: 2px 2px 4px #000;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

/* Add images/logo192.png centered in the background */
.slides {
    background-image: url('../public/logo192.png');
    background-repeat: no-repeat;
    background-position: center;
    z-index: 9999;
}

loading-rotate-screen .a-loader-title {
    text-shadow: 2px 2px 4px #000;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #fff;
}

@keyframes gradient-animation {
    0% {
        background-position: 200% 0;
    }

    100% {
        background-position: -200% 0;
    }
}

.loading-nfts {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    background: linear-gradient(0deg, #3730A3, #1E2A3B, #0033E9);
    background-size: 200% 200%;
    color: #fff;
    text-align: center;
    animation: falling-gradient 3s linear infinite alternate;
    overflow-y: auto;
}

.landing-page {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    background: linear-gradient(0deg, #3730A3, #1E2A3B, #0033E9);
    background-size: 200% 200%;
    color: #fff;
    text-align: center;
    animation: falling-gradient 10s linear infinite alternate;
    overflow-y: auto;
}

.loading-rotate-screen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    background: linear-gradient(90deg, #3730A3, #1E2A3B, #0033E9);
    background-size: 200% 200%;
    color: #fff;
    text-align: center;
    animation: falling-gradient 3s linear infinite alternate;
}

@keyframes falling-gradient {
    0% {
        background-position: 100% 0;
    }

    100% {
        background-position: 0 100%;
    }
}

/* CookieConsentStyles.css */

.custom-cookie-consent {
    background: darkblue;
    z-index: 999999 !important;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
}

.custom-cookie-button {
    background: lightgreen;
    color: black;
    padding: 10px 20px;
    font-size: 16px;
}

.NFTAttributeContainer {
    margin: 0;
    padding: 0;
    text-align: left;
}

.NFTAttribute {
    font-size: x-small;
}

.NFTDescription {
    font-size: small;
    white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 250px;
    max-width: 250px;
    text-align: center;
    /* Adjust the value as needed */
}

.NFTDescriptionLongText {
    font-size: xx-small;
    white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 250px;
    max-width: 250px;
    text-align: center;
    /* Adjust the value as needed */
}

.NFTImage {
    max-width: 80%;
    padding: 0;
    margin: 0;
}

.NFTName {
    font-size: x-large;
    white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 250px;
    max-width: 250px;
    text-align: center;
}

.NFTOwner {
    font-size: xx-small;
    margin: 0;
    padding: 0;
    float: inline-end;
}

.NFTInfo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;    
}

@media screen and (orientation: portrait) {
    .NFTInfo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .NFTOwner {
        /* Place it at the top center floating */
        font-size: xx-small;
    }

    .NFTAttributeContainer {
        margin: 0;
        padding: 0;
        text-align: left;
    }

    .NFTDescription {
        font-size: small;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
    }    

    .NFTDescriptionLongText {
        font-size: xx-small;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
    }    

    .NFTName {
        font-size: x-large;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
    }

    .NFTImage {
        max-width: 70%;
    }
}